.sbs-img {
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 200px;
}

.sbs-link {
    height: 100%;
    display: block;
}

.sbs-container {
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0 0 30px 0 #00000044;
}
