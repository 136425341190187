@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.hero {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: content-box;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 93%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 93%, 0 100%);
}

.hero h1 {
    text-transform: uppercase;
    font-size: 5rem;
    font-weight: 700;
    color: black;
    text-shadow: 0 0 60px white;
    margin: 0;
    padding: 0;
    z-index: 1;

    @media (max-width: map-get($grid-breakpoints, "sm")){
        font-size: 3.5rem;
    }
}

.hero h1::after {
    content: '';
    width: 200px;
    height: 200px;
    border-radius: 100%;
    background: rgba(79, 206, 157, 0.6);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}