//Font Import
@import url("https://use.typekit.net/fxi6rig.css");
@import './scss/nobile_font.scss';

// Custom.scss

$theme-colors: (
    "primary": rgb(79, 206, 157),
);
$navbar-light-hover-color: rgba(map-get($theme-colors, "primary"), 0.5);
$navbar-light-active-color: map-get($theme-colors, "primary");

@import "node_modules/bootstrap/scss/bootstrap.scss";

html {
    overflow-y: scroll;
}

p,
strong {
    font-family: "Nobile";
}

.preface {
    font-size: "24px";
    font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "teko";
    font-style: normal;
    font-weight: 400;
}

$current-nav-breakpoint: "md";

.navbar {
    .container {
        align-items: flex-end;
        padding: 1.5rem 1rem 0rem 1rem;
    }
    .nav-link {
        @media (min-width: map-get($grid-breakpoints, $current-nav-breakpoint)) {
            padding: 0rem 1rem;
            height: 1em;
        }
        @media (max-width: map-get($grid-breakpoints, $current-nav-breakpoint)) {
            margin-right: auto;
        }
        font-weight: 800;
        font-size: 18px;
        display: flex;
        align-items: center;
    }
    .navbar-brand {
        padding-bottom: 0;
    }
    .navbar-nav {
        @media (max-width: map-get($grid-breakpoints, $current-nav-breakpoint)) {
            margin-top: 2rem;
        }
    }
}

.heading-left,
.heading-right {
    display: inline-block;
    position: relative;
    font-size: 3.5rem;
}

.heading-left::before {
    position: absolute;
    left: -5%;
    bottom: 0.8rem;
    width: 80%;
    height: 20px;
    background: linear-gradient(151deg, rgba(5, 116, 73, 1) 0%, rgba(79, 206, 157, 1) 59%, rgba(174, 255, 224, 1) 100%);
    content: "";
    transform: skew(-12deg);
    z-index: -1;
}

.heading-right::before {
    position: absolute;
    right: -5%;
    bottom: 0.8rem;
    width: 80%;
    height: 20px;
    background: linear-gradient(151deg, rgba(174, 255, 224, 1) 0%, rgba(79, 206, 157, 1) 59%, rgba(5, 116, 73, 1) 100%);
    content: "";
    transform: skew(-12deg);
    z-index: -1;
}
